import HomeView from "@/views/HomeView";
import ClockView from "@/views/rme/ClockView";
import GalleryView from "@/views/gallery/GalleryView.vue";


const routes = [
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: LoginView,
    // },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: RegisterView,
    // },
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/clock',
        name: 'clock',
        component: ClockView
    },
    {
        path: '/gallery/:name',
        name: 'gallery',
        component: GalleryView
    }
]


export default routes
