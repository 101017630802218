<template>
  <notifications>

</notifications>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.check()
  },
  methods:{
    check(){

      if(!localStorage.auth){
        let response = prompt("Gib das Passwort ein!")
        fetch(this.apihost + "/auth/login",
            {
              method: "GET",
              headers: {
                'Authorization': response,
                'Content-Type': 'application/json',
              }
            }).then(res => {
          if(res.status === 200){
            localStorage.auth = response;
          }else {
            this.check()
          }
        })
      }
    }
  }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*font-family: 'Montserrat', sans-serif;*/
}

body{
  margin: 0;
  background-color: #EBEDEF;
}
</style>
