<template>
  <router-link
      :class="['h-80 w-80 m-1 p-1 pb-8 rounded group relative', color]"
      :to="to">
    <div class="w-full h-full bg-blue-900 relative">
      <div class="group-hover:block hidden absolute x-0 y-0 bg-blue-600 w-full h-full bg-opacity-80 transition ease-in duration-300 text-m text-gray-300 pt-3">
        {{ desc }}
      </div>
      <img :src="image" class="object-cover w-full h-full"/>
    </div>
    <div class="absolute text-l font-bold text-white bottom-1 -translate-x-1/2 left-1/2 transform">{{title}}</div>
  </router-link>
</template>

<script>
export default {
  name: "HomeLinkComponent",
  props: {
    title: {
      type: String
    },
    desc: {
      type: String
    },
    to: {
      type: String
    },
    image: {
      type: Image
    },
    icon: {
      type: String
    },
    type: {
      type: String
    },
    color: {
      type: String
    },
  },
}
</script>

<style scoped>

</style>
