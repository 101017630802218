import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App)
    .use(Notifications);

app.config.globalProperties.apihost = 'https://api.lukasschmidt.eu'
// app.config.globalProperties.apihost = 'http://localhost:8092'


app.config.globalProperties.success = function(title, text){
    app.config.globalProperties.$notify({
        title: title,
        text: text,
        type: 'success'
    });
}

app.config.globalProperties.jsonParser = function (res) {
    if (res.status === 200) {
        return res.json()
    } else if (res.status === 401) {
        app.config.globalProperties.$notify({
            title: "Fehler",
            text: "Logge dich zunächst ein!",
            type: 'error'
        });
    }else if (res.status === 404) {
        app.config.globalProperties.$notify({
            title: "Fehler",
            text: "Diese Seite existiert nicht!",
            type: 'error'
        });
    } else if (res.status === 403) {
        app.config.globalProperties.$notify({
            title: "Fehler",
            text: "Verifiziere zunächst deine E-Mail adresse!",
            type: 'error'
        });
    } else {
        app.config.globalProperties.$notify({
            title: "Fehler " + res.status,
            text: res.text(),
            type: 'error'
        });
    }
}

app
    .use(router)
    .mount('#app')
