<template>


  <div :class="[show ? '' : 'hidden', 'fixed top-0 left-0']" id="modal">
    <div class="absolute z-10 h-screen w-screen bg-black bg-opacity-50" @click="this.show = false">
    </div>

      <img id="Image" class="z-10 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-150" :src="image" />

    <i class="fas fa-chevron-left   z-10 fixed top-1/2 -translate-y-1/2 left-0 text-3xl text-gray-200 hover:scale-150 transition p-10"   @click="emit('before')"></i>
    <i class="fas fa-chevron-right   z-10 fixed top-1/2 -translate-y-1/2 right-0 text-3xl text-gray-200 hover:scale-150 transition p-10" @click="emit('next')"></i>



  </div>

</template>

<script>

export default {
  name: "ImageViewer",
  data() {
    return {
      image: "",
      show: false
    }
  },
  methods: {
    open(img){
      this.image = img
      this.show = true;
    },
    emit(name){
      console.log(name)
      this.$emit(name)
    }
  }
}
</script>

<style scoped>
#modal{
  z-index: 5;
}
</style>
