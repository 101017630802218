<template>
  <TopBar :title="'Gallerie: ' + $route.params.name"/>

  <GalleryAddModal
      @refresh="loadData"
      ref="modal"
  />

  <ImageViewer
      ref="viewer"
      @before="changeView(-1)"
      @next="changeView(1)"
  />


  <div class="flex flex-wrap gap-5 m-10">


    <router-link class="h-80 w-80 bg-gray-300 z-0 rounded hover:bg-gray-400 relative transition" to="/gallery/home">
      <i class="fas fa-home text-8xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></i>

      <span class="text-xl w-full font-bold absolute bottom-4 left-1/2 -translate-x-1/2">Start-Verzeichnis</span>

    </router-link>

    <router-link :to="'/gallery/' + folder"
                 class="h-80 w-80 bg-gray-300 relative hover:bg-gray-400 transition"
                 v-for="folder in database.folders"
                 :key="folder">
      <i class="fas fa-folder  text-8xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></i>

      <span class="text-xl w-full font-bold absolute bottom-4 left-1/2 -translate-x-1/2">{{ folder }}</span>
    </router-link>


    <div
        @click="viewImage(img, key)"
        target="_blank"
        class="bg-gray-300 overflow-hidden hover:scale-110 transition hover:cursor-pointer"
        v-for="(img,key) in database.items"
        :key="img">
      <img class="h-80 w-80 object-cover" :src="generateLink(img)" loading="lazy">
    </div>


    <div class="h-80 w-80 bg-gray-400 relative hover:bg-gray-300 hover:cursor-pointer transition"
         @click="$refs.modal.show">
      <i class="fas fa-plus  text-8xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></i>
      <span class="text-xl w-full font-bold absolute bottom-4 left-1/2 -translate-x-1/2">Neu</span>
    </div>

  </div>

  <ImageViewer ref="viewer"/>
</template>


<script>
import TopBar from "@/components/TopBar.vue";
import GalleryAddModal from "@/components/modals/GalleryAddModal.vue";
import ImageViewer from "@/components/ImageViewer.vue";


export default {
  name: "GalleryView",
  components: {ImageViewer, GalleryAddModal, TopBar},
  data() {
    return {
      database: {},
      currentViewed: -1,
    }
  },
  mounted() {
    if (!localStorage.authorize) {
      this.authorize();
    } else {
      this.loadData()
    }
  },
  methods: {
    loadData() {
      fetch(this.apihost + "/gallery/folder/" + this.$route.params.name + "?authentication=" + localStorage.authorize,
          {
            method: "GET",
            headers: {
              'Authorization': localStorage.auth
            }
          }).then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 401) {
          this.authorize();
          return {};
        } else if (res.status === 403) {
          this.authorize();
          return {};
        } else {
          alert("Error: " + res.status)
        }
      })
          .then(value => {
            this.database = value;
          })
    },
    authorize() {
      let response = prompt("Gib das OPT ein!")
      fetch(this.apihost + "/gallery/auth?auth=" + response,
          {
            method: "GET",
            headers: {
              'Authorization': localStorage.auth
            }
          }).then(res => {
        if (res.status === 200) {
          return res.text();
        } else if (res.status === 403) {
          this.authorize();
          return {};
        } else {
          alert("Error: " + res.status)
          this.authorize();
        }
      })
          .then(value => {
            if (value.length === 36) {
              localStorage.authorize = value;
              this.loadData();
            }
          })
    },
    generateLink(id) {
      return this.apihost + '/gallery/image/' + id + '?authorization=' + localStorage.auth + "&authentication=" + localStorage.authorize;
    },
    viewImage(img, key) {
      this.currentViewed = key;
      this.$refs.viewer.open(this.generateLink(img))
    },
    changeView(amount) {
      console.log(amount)
      this.currentViewed += amount;
      this.viewImage(this.database.items[this.currentViewed], this.currentViewed);
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  }
}
</script>

<style scoped>

</style>
