<template>

  <TopBar title="Home v23.9"/>

  <div class="mx-20 mt-5 flex flex-wrap gap-5">
    <HomeLinkComponent
        title="Stempel-Uhr"
        desc="Stempeln für RME"
        to="/clock"
        image="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Station_Clock.svg/220px-Station_Clock.svg.png"
        icon="fa-clock"
        type="RME"
        color="bg-blue-600"
    />
    <HomeLinkComponent
        title="Gallerie"
        desc="Zeige Bilder an"
        to="/gallery/home"
        image="http://img.primeapi.de/2023/09/15/jqFj"
        type="NONE"
        color="bg-green-500"
    />
    <HomeLinkComponent
        title="Folgt"
        desc="Folgt"
        to="/"
        image="http://img.primeapi.de/2023/09/13/R4hT"
        icon="fa-clock"
        type="NONE"
        color="bg-yellow-500"
    />
    <HomeLinkComponent
        title="Folgt"
        desc="Folgt"
        to="/"
        image="http://img.primeapi.de/2023/09/13/R4hT"
        icon="fa-clock"
        type="NONE"
        color="bg-yellow-500"
    />
    <HomeLinkComponent
        title="Folgt"
        desc="Folgt"
        to="/"
        image="http://img.primeapi.de/2023/09/13/R4hT"
        icon="fa-clock"
        type="NONE"
        color="bg-yellow-500"
    />
    <HomeLinkComponent
        title="Folgt"
        desc="Folgt"
        to="/"
        image="http://img.primeapi.de/2023/09/13/R4hT"
        icon="fa-clock"
        type="NONE"
        color="bg-yellow-500"
    />
    <HomeLinkComponent
        title="Folgt"
        desc="Folgt"
        to="/"
        image="http://img.primeapi.de/2023/09/13/R4hT"
        icon="fa-clock"
        type="NONE"
        color="bg-yellow-500"
    />
  </div>

</template>

<script>
import TopBar from "@/components/TopBar";
import HomeLinkComponent from "@/components/HomeLinkComponent";
export default {
  name: "HomeView",
  components: {HomeLinkComponent, TopBar}
}
</script>

<style scoped>

</style>
