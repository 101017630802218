<template>
  <TopBar title="Stempel-Uhr"/>
  <ClockEditModal @refresh="loadClockList" ref="modal"/>


  <div class="grid grid-cols-1 md:grid-cols-2 gap-10 p-5">
    <div class="p-5 rounded bg-gray-300 w-full">

      <div style="text-align: left;">
        <h1 class="text-3xl font-bold align-left">Stempel Uhr</h1>
        <hr class="w-1/4 border-blue-500 border-2">

        <div class="mt-5 grid grid-cols-1 sm:grid-cols-2 gap-5">
          <button @click="start" class="p-5 bg-green-500" type="button">Einstempeln</button>
          <button @click="end" class="p-5 bg-red-500 button">Ausstempeln</button>
        </div>
      </div>
    </div>
    <div class="p-5 rounded bg-gray-300 w-full">

      <div style="text-align: left;">
        <h1 class="text-3xl font-bold align-left">Zeitkonto</h1>
        <hr class="w-1/4 border-blue-500 border-2">

        <div class="mt-4">
          <div class="text-xl font-bold">Aktuelles Konto:</div>
          <div :class="['text-xl font-semibold', totalMinutes >= 0 ? 'text-green-500' : 'text-red-500']">{{
              (totalMinutes / 60).toFixed(2)
            }}
          </div>
        </div>
        <div class="mt-1">
          <div class="text-sm font-bold">Letzter Monat:</div>
          <div :class="['text-sm font-semibold', 0 >= 0 ? 'text-green-500' : 'text-red-500']">{{
              (0 / 60).toFixed(2)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="p-5 m-5 rounded bg-gray-300 color-black">
    <div style="text-align: left;">
      <div class="flex justify-between">
        <h1 class="text-3xl font-bold align-left">Stempel-Tabelle</h1>
        <div class="flex gap-1">
          <div class="flex flex-col">
            <span>Start</span>
            <input v-model="dates.start" @change="loadClockList" type="date">
          </div>
          <div class="flex flex-col">
            <span>Ende</span>
            <input v-model="dates.end" @change="loadClockList"  type="date">
          </div>
        </div>
      </div>
      <hr class="w-1/4 border-blue-500 border-2">
    </div>

    <table class="w-full mt-1">
      <thead>
      <tr class="bg-gray-300">
        <th scope="col"
            class=""
        >
          Datum
        </th>
        <th scope="col"
            class=""
        >
          Start
        </th>
        <th scope="col"
            class=""
        >
          Ende
        </th>
        <th scope="col"
            class=""
        >
          Zeit
        </th>
        <th scope="col"
            class=""
        >
          Summe
        </th>
        <th scope="col"
            class=""
        >
          Typ
        </th>
        <th scope="col"
            class=""
        >
        </th>
      </tr>
      </thead>

      <tbody class="text-black">
      <tr v-for="arr in reversed(clockList)" :key="arr" :class="['border-l-8 odd:bg-gray-400', getBorderColor(arr[1])]">
        <td class="">
          {{getDay(new Date(arr[0]).getDay()) + ", " +
            arr[0]}}
        </td>
        <td :class="[arr[1].ignoreClock ? 'line-through' : '']">
          {{
            arr[1].start ? twoDigit(new Date(arr[1].start).getHours()) + ":" + twoDigit(new Date(arr[1].start).getMinutes()) + " Uhr" : "-"
          }}
        </td>
        <td :class="[arr[1].ignoreClock ? 'line-through' : '']">
          {{
            arr[1].end ? twoDigit(new Date(arr[1].end).getHours()) + ":" + twoDigit(new Date(arr[1].end).getMinutes()) + " Uhr" : "-"
          }}
        </td>
        <td :class="[arr[1].ignoreClock ? 'line-through' : '']">
          {{ calculateTimeDisplayed(arr[1]) }}
        </td>
        <td :class="['font-bold', arr[1].ignoreClock ? 'line-through' : '', calculateDiff(arr[1]) > 0 ? 'text-green-500' : '', calculateDiff(arr[1]) < 0 ? 'text-red-500' : '']">
          {{ calculateDiff(arr[1]) }}
        </td>
        <td>
          {{ arr[1].type }}
        </td>
        <td @click="$refs.modal.show(arr)">
          <i class="fas fa-ellipsis-v"></i>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>
import TopBar from "@/components/TopBar";
import ClockEditModal from "@/components/modals/ClockEditModal";

export default {
  name: "ClockView",
  components: {ClockEditModal, TopBar},
  data() {
    return {
      clockList: [],
      totalMinutes: 0,
      dates: {
        start: '2023-' + new Date().getMonth() + '-06',
        end: '2023-09-06'
      }
    }
  },
  mounted() {
    this.calculateStartDate();
    this.loadClockList()
  },
  methods: {
    reversed(map){
      return Object.entries(map).reverse();
    },
    calculateStartDate(){
      let date = new Date();
      this.dates.start = date.getFullYear() + "-" + this.twoDigit( date.getMonth() + 1) + "-01";
      this.dates.end = date.getFullYear() + "-" + this.twoDigit( date.getMonth() + 1) + "-" + this.twoDigit(date.getDate());
      console.log(this.dates)
    },
    getBorderColor(clock) {
      if (clock.type === "WEEKEND") {
        return "border-yellow-400"
      }
      if (clock.ignoreClock) {
        return "border-gray-200"
      }
      if (!clock.start && !clock.end) {
        return "border-red-500"
      }
      let time = this.calculateTime(clock);
      if (time < 7.5) {
        return "border-red-300"
      } else
        return "border-green-500"
    },
    calculateTime(clock) {
      let start = new Date(clock.start);
      let end = new Date(clock.end);
      let secs = (end.getTime() - start.getTime()) / 1000;
      let min = secs / 60;
      let hours = min / 60;
      return hours.toFixed(2);
    },
    calculateTimeDisplayed(clock) {
      // let time =  "" + this.calculateTime(clock);
      // let h = time.split(".")[0];
      // let min = this.twoDigit((parseFloat("0." + time.split(".")[1]) * 60).toFixed(0));
      // return h + ":" + min + " (" + time + "h)";

      let time = this.calculateTime(clock);
      let work = time - (time >= 6 ? 0.5 : 0);
      return work.toFixed(2) + "h (" + time + "h)"
    },
    calculateDiff(clock) {
      let time = this.calculateTime(clock)
      if (time >= 6) time -= 0.5;
      time -= 7;
      return time.toFixed(2);
    },
    twoDigit(number) {
      if (number <= 9) {
        return "0" + number
      } else {
        return number;
      }
    },

    getDay(day) {
      switch (day) {
        case 1:
          return "Mo";
        case 2:
          return "Di";
        case 3:
          return "Mi"
        case 4:
          return "Do"
        case 5:
          return "Fr"
        case 6:
          return "Sa"
        case 0:
          return "So"
      }
    },
    loadClockList() {
      fetch(this.apihost + "/clock/month?start=" + this.dates.start + "&end=" + this.dates.end,
          {
            method: "GET",
            headers: {
              'Authorization': localStorage.auth
            }
          }).then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          alert("Error: " + res.status)
        }
      })
          .then(value => {
            this.clockList = value.table;
            this.totalMinutes = value.currentTotalMinutes;
          })
    },
    start() {
      fetch(this.apihost + "/clock/start",
          {
            method: "GET",
            headers: {
              'Authorization': localStorage.auth,
            },
          }).then(res => {
        if (res.status === 200) {
          this.success("Erfolg", "Du hast ein eingestempelt!")
          this.loadClockList()
          return null;
        } else if (res.status === 400) {
          this.$notify({
            title: "Fehler",
            text: "Du hast dich heute bereits eingestempelt!",
            type: 'error'
          });
        } else {

          this.$notify({
            title: "Fehler",
            text: "Unbekannter Fehler",
            type: 'error'
          });
        }
      })
    },
    end() {
      fetch(this.apihost + "/clock/stop",
          {
            method: "GET",
            headers: {
              'Authorization': localStorage.auth,
            },
          }).then(res => {
        if (res.status === 200) {
          this.success("Erfolg", "Schönen Feierabend!")
          this.loadClockList()
          return null;
        } else if (res.status === 400) {
          this.$notify({
            title: "Fehler",
            text: "Du musst dich vorher einstempeln!",
            type: 'error'
          });
        } else {

          this.$notify({
            title: "Fehler",
            text: "Unbekannter Fehler",
            type: 'error'
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
