<template>

  <div :class="[open ? '' : 'hidden', 'fixed top-0 left-0']" id="modal">

    <div class="absolute z-5 h-screen w-screen bg-black bg-opacity-50" @click="this.open = false">
    </div>

    <div class="w-screen z-10 absolute flex justify-center content-center">
      <div class="h-min mt-32 px-0 bg-gray-300 text-black">
        <h1 class="text-xl font-bold px-16 p-6">Hinzufügen</h1>
        <hr class="w-full border-blue-500 border-2">

          <div class="flex w-full justify-between">
            <div class="flex flex-col w-1/2 ">
              <button @click="upload" class="py-5" >Bild hochladen</button>
              <hr>
              <button @click="createFolder" class="py-5" >Ordner hinzufügen</button>
            </div>
            <div class="flex flex-col w-1/2">
              <input v-model="form.link" class="py-5" type="text" placeholder="https://...">
              <hr>
              <input v-model="form.name" class="py-5"  type="text" placeholder="Name">
            </div>
          </div>
          <hr>
          <button class="p-4 bg-red-100 w-full" type="button" @click="open = false">
            <span>Schließen</span>
          </button>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "GalleryAddModal",
  data() {
    return {
      open: false,
      form: {
        link : "",
        name: "",
      }
    }
  },
  methods: {
    show() {
      this.open = true;
    },
    upload(){

      fetch(this.apihost + "/gallery/folder/" + this.$route.params.name + "?authentication=" + localStorage.authorize,
          {
            method: "POST",
            headers: {
              'Authorization': localStorage.auth,
              'Content-Type': 'application/text',
            },
            body: this.form.link,
          }).then(res => {
        if (res.status === 200) {
          this.success("Success", "Bild hochgeladen!");
          this.$emit("refresh")
          this.open = false;
        } else {
          alert("Error: " + res.status)
        }
      })

    },
    createFolder(){

      fetch(this.apihost + "/gallery/addfolder/" + this.$route.params.name + "?authentication=" + localStorage.authorize,
          {
            method: "POST",
            headers: {
              'Authorization': localStorage.auth,
              'Content-Type': 'application/text',
            },
            body: this.form.name,
          }).then(res => {
        if (res.status === 200) {
          this.success("Success", "Ordner erstellt!");
          this.$emit("refresh")
          this.open = false;
        } else {
          alert("Error: " + res.status)
        }
      })

    },
    twoDigit(number){
      if(number <= 9){
        return "0" + number
      }else {
        return number;
      }
    },
  }
}
</script>

<style scoped>
#modal{
  z-index: 10;
}
</style>
