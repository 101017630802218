<template>
  <div class="w-full p-5 flex justify-between bg-gray-300">
      <router-link class="bg-gray-400 p-3 hover:bg-gray-500 rounded-xl" to="/">
        <i class="fas fa-home"></i>
      </router-link>
    <div class="text-xl font-bold">{{ title }}</div>
    <div>
      {{ time }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      time: '',
      date: ''
    }
  },
  props: {
    title: {
      type: String
    },
  },
  mounted() {
    this.displayTime();
  },
  methods: {
    displayTime() {
      this.time = new Date().toLocaleTimeString()
      setTimeout(this.displayTime, 1000)
    }
  }
}
</script>

<style scoped>

</style>
