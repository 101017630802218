<template>

  <div :class="[open ? '' : 'hidden', 'fixed top-0 left-0']">

    <div class="absolute z-5 h-screen w-screen bg-black bg-opacity-50" @click="this.open = false">
    </div>

    <div class="w-screen z-10 absolute flex justify-center content-center">
      <div class="h-min mt-32 px-0 bg-gray-300 text-black">
        <h1 class="text-xl font-bold px-16 p-6">Bearbeiten: {{day }}.{{ month }}.{{ new Date().getFullYear() }}</h1>
        <hr class="w-full border-blue-500 border-2">

        <form>

          <div class="flex w-full justify-between">
            <div class="flex flex-col w-1/2 ">
              <div class="py-5" >Start</div>
              <div class="py-5" >Ende</div>
              <div class="py-5" >Typ</div>
              <div class="py-5" >Ignore</div>
            </div>
            <div class="flex flex-col w-1/2">
              <input v-model="form.start" class="py-5" type="time">
              <input v-model="form.end" class="py-5"  type="time">
              <select @change="changeSelect" v-model="form.type" class="py-5" >
                <option>URLAUB</option>
                <option>BERUFSSCHULE</option>
                <option>GLEITZEIT</option>
                <option>WEEKEND</option>
                <option>NORMAL</option>
              </select>
              <select v-model="form.ignore" class="py-5" >
                <option>true</option>
                <option>false</option>
              </select>
            </div>
          </div>

          <hr>
          <button class="p-4 w-full" type="button" @click="submit">
            <span>Speichern</span>
          </button>
          <hr>
          <button class="p-4 bg-red-100 w-full" type="button" @click="open = false">
            <span>Schließen</span>
          </button>
        </form>

      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "ClockEditModal",
  data() {
    return {
      open: false,
      clock: {},
      day: 0,
      month: 0,
      form: {
        start : "",
        end: "",
        type: "NORMAL",
        ignore: false
      }
    }
  },
  methods: {
    show(arr) {
      this.clock = arr[1];
      let date = new Date(arr[0]);
      this.day = this.twoDigit(date.getDate());
      this.month = this.twoDigit(date.getMonth() + 1);
      console.log(this.day)
      console.log(this.month)
      if(this.clock.start)
      this.form.start = this.twoDigit(new Date(this.clock.start).getHours()) + ":" + this.twoDigit(new Date(this.clock.start).getMinutes())
      if(this.clock.end)
      this.form.end = this.twoDigit(new Date(this.clock.end).getHours()) + ":" + this.twoDigit(new Date(this.clock.end).getMinutes())
      this.form.type = this.clock.type;
      this.form.ignore = this.clock.ignoreClock
      this.open = true;
    },
    submit(){
      let year = new Date().getFullYear();
      let start = year + "-" + this.month + "-" + this.day + "T" + this.form.start + ":00.0"
      let end = year + "-" + this.month + "-" + this.day + "T" + this.form.end + ":00.0"
      this.clock.start = start;
      this.clock.end = end;
      this.clock.type = this.form.type;
      this.clock.ignoreClock = this.form.ignore == "true";

      fetch(this.apihost + "/clock/edit",
          {
            method: "PATCH",
            headers: {
              'Authorization': localStorage.auth,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.clock),
          }).then(res => {
        if (res.status === 200) {
         this.success("Success", "Eintrag erfolgreich gespeichert!");
         this.$emit("refresh")
         this.open = false;
        } else {
          alert("Error: " + res.status)
        }
      })

    },
    changeSelect(){
      if(this.form.type !== "NORMAL"){
        this.form.start = "08:00";
        this.form.end = "15:30"
      }
    },
    twoDigit(number){
      if(number <= 9){
        return "0" + number
      }else {
        return number;
      }
    },
  }
}
</script>

<style scoped>

</style>
